<template>
  <div>
    <img
      class="hidden-sm-and-down"
      style="width: 100%"
      src="../../assets/mock/scene/solution2.jpg"
      alt=""
    />
    <img
      class="hidden-md-and-up"
      style="width: 100%"
      src=""
      alt=""
    />
    <div class="warp">
      <div class="top">
        <h3>全部概览</h3>
        <span></span>
        <p>宇起智能指纹锁为企业办公用柜提供了配套的解决方案，不仅方便了员工的使用，还方便了企业管理员的管理，我们全力为企业用户提供安全、便捷、智能的工作方式。</p>
      </div>
      <div class="top">
        <h3>企业商场应用场景</h3>
        <span></span>
        <img style="width: 100%;" src="../../assets/mock/scene/solution2.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
 .top {
    text-align: center;
    margin: 3.125rem 0;
    h3 {
      margin-top: 1.25rem;
    }
    span {
      width: 1.25rem;
      height: 3px;
      display: inline-block;
      background-color: #3cb59e;
    }
  }
</style>